.SupportedCryptoSection {
  align-items: center;
  justify-content: center;

  margin-top: 106px;
}

.SupportedCryptoSection_container {
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;

  justify-self: center;
}

.SupportedCryptoSection_title {
  text-align: center;

  color: var(--primary);
}

.SupportedCryptoSection_subtitle {
  text-align: center;
}

.SupportedCryptoSection_searchBlock {
  display: flex;
  justify-content: center;
}

.SupportedCryptoSection_search {
  position: relative;

  overflow: hidden;

  box-sizing: content-box;
  width: 790px;
  height: 80px;
  margin-top: 12px;

  border: 1px solid var(--dark-30);
  border-radius: 20px;
  background: var(--bright-50);
}

.SupportedCryptoSection_exit {
  position: absolute;
  top: 29px;
  right: 36px;

  cursor: pointer;
}

.SupportedCryptoSection_input {
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 28px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 2px 32px;

  border: none;
  outline: none;
}

.SupportedCryptoSection_input::placeholder {
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 28px;

  color: var(--dark-50);
}

.SupportedCryptoSection_table {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 1200px;
  height: 658px;
  margin-top: 12px;
  padding: 23px 54px;
  margin-bottom: 75px;

  border-radius: 20px;
  /* brand/@primary:05 */
  background: var(--primary-05);
}

.SupportedCryptoSection_item {
  display: flex;
  align-items: center;

  min-width: 50%;
  min-height: 38px;
  margin: 15px 0;
  padding-left: 17px;

  color: var(--dark-30);
}

.SupportedCryptoSection_numberCoin {
  min-width: 40px;
}

.SupportedCryptoSection_imgCoin {
  min-width: 38px;
  min-height: 38px;
  margin: 0 12px 0 9px;
}

.SupportedCryptoSection_nameCoin {
  margin-right: 12px;

  color: var(--dark);
}

.SupportedCryptoSection_buttonContainer {
  display: flex;
  justify-content: center;

  margin-top: 34px;
}

.SupportedCryptoSection_button {
  width: 40px;
  height: 40px;

  cursor: pointer;

  border: none;
  border-radius: 50%;
  outline: none;
  background-color: transparent;
}

.SupportedCryptoSection_button__active {
  color: var(--text-white);
  background-color: var(--primary);
}

.SupportedCryptoTable_textEmprty {
  margin: auto 0;

  text-align: center;
}

.SupportedCryptoSection_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.SupportedCryptoSection_placeholderPerson {
  width: 60px;
  margin-right: 34px;

  transform: rotate(-5deg);
}

.SupportedCryptoSection_placeholderCloud {
  position: relative;

  display: block;

  max-width: 401px;
  max-height: 100%;
  padding: 22px 25px;

  border-radius: 20px;
  background: var(--primary-05);
}

.SupportedCryptoSection_placeholderCloud span {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  line-clamp: 15; /* Add this line */
}

.SupportedCryptoSection_placeholderCloud::after {
  position: absolute;
  top: 50%;
  left: -21px;

  display: block;

  width: 21px;
  height: 17px;

  content: "";
  transform: translateY(-50%);

  background-image: url("data:image/svg+xml,%3Csvg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.0876 0.28125C13.7384 3.38263 5.51406 7.95968 0.0876465 13.9196C10.4715 11.0128 17.2294 13.9606 21.0876 17.6859V0.28125Z' fill='%23226DFF' fill-opacity='0.05'/%3E%3C/svg%3E%0A");
}
