:root {
  --height-menu: 106px;
}

.HeaderSection {
  position: relative;

  overflow: hidden;
  align-items: center;
  flex-direction: column;

  box-sizing: content-box;
  height: 100vh;

  color: var(--text-white);
  background: var(--gradient-main);
}

.HeaderSection_menu {
  min-height: 106px;
  margin: 29px 0;
}

.HeaderSection_welcomeSection {
  overflow: hidden;
}

.HeaderSection_main {
  display: flex;
  align-items: center;

  height: 100vh;
  margin-top: -135px;

  justify-self: center;
}
.HeaderSection_container {
  z-index: 10;

  padding-top: 72px;
}

.HeaderSection_overflow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.HeaderSection_welcome {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 688px;
  /* height: 457px; */
  margin-right: -210px;
}

.HeaderSection_title {
  font-size: 48px;
  font-weight: 500;
  line-height: 52px;

  display: block;

  width: 688px;
  margin-bottom: 33px;

  color: var(--text-white);
}

.HeaderSection_description {
  font-size: 18px;
  font-style: normal;
  line-height: 30px;

  display: block;

  width: 649px;

  letter-spacing: 0.25px;

  color: var(--text-white);
}

.HeaderSection_mobile {
  position: absolute;
  z-index: var(--z-index-front-img);
  top: 0;
  right: 40px;
}

.HeaderSection_mobile img {
  height: 700px;
}

.HeaderSection_triangle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: -50%;

  height: 370px;

  transform: translate(0, 50%);

  background: var(--bright);

  clip-path: polygon(100% 0%, 0% 73%, 100% 100%);
}

.HeaderSection_buttons {
  display: flex;

  height: 50px;
  margin-top: 60px;
}

.HeaderSection_buttons a {
  width: 205px;
}

.HeaderSection_buttons a:last-child {
  margin-left: 20px;
}

.HeaderSection_getStartedButton {
  padding: 10px 53px;
}

.HeaderSection_downloadButton {
  margin-left: 12px;
  padding: 10px 43px;
}
