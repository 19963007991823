.EtzMobileSection {
  justify-content: center;

  height: 100%;
  /* min-height: calc(100vh + var(--height-menu)); */
  /* padding: 460px 0; */
  padding-top: var(--section-margin-top);

  --coin-position: 1px;
}

.EtzMobileSection_mobiles {
  position: relative;

  display: flex;
  justify-content: center;
  /*
  min-width: 938px;
  height: 1078px;*/
  /* height: clamp(1030px, 1vh, 300px); */
  /* max()(MIN, min()(VAL, MAX)) */
  /* transform: scale(max(1, calc(var(--vh) / 1038), 0)); */

  transform: scale(clamp(0.5, calc(var(--vh) / 1038), 1));
}

.EtzMobileSection_img {
  height: 1038px;
  /* height: clamp(1030px, 100vh, 300px); */
  /* min-height: 100%; */
  /* height: min(1030px, 100vh - 80px); */
}

.EtzMobileSection_coin {
  position: absolute;
  z-index: 1;

  --name: pulse;
  --time-function: ease-in-out;
}

.EtzMobileSection_ada {
  top: calc(613px + var(--coin-position));
  left: -21px;

  animation: var(--name) 4900ms var(--time-function) infinite;
}

.EtzMobileSection_avax {
  top: calc(340px + var(--coin-position));
  left: 687px;

  animation: var(--name) 5200ms var(--time-function) infinite;
}

.EtzMobileSection_bnb {
  top: calc(220px + var(--coin-position));
  left: -35px;

  animation: var(--name) 4800ms 40ms var(--time-function) infinite;
}

.EtzMobileSection_btc {
  top: calc(280px + var(--coin-position));
  left: 390px;

  animation: var(--name) 4700ms 10ms var(--time-function) infinite;
}

.EtzMobileSection_eth {
  right: 246px;
  bottom: calc(312px - var(--coin-position));

  animation: var(--name) 5000ms 20ms var(--time-function) infinite;
}

.EtzMobileSection_ftm {
  top: calc(163px + var(--coin-position));
  left: -120px;

  animation: var(--name) 4500ms 10ms var(--time-function) infinite;
}

.EtzMobileSection_sol {
  top: calc(70px + var(--coin-position));
  right: 345px;

  animation: var(--name) 4000ms 5ms var(--time-function) infinite;
}

.EtzMobileSection_usdc {
  bottom: calc(101px - var(--coin-position));
  left: 369px;

  animation: var(--name) 5500ms var(--time-function) infinite;
}

.EtzMobileSection_block {
  position: relative;

  display: flex;
  overflow: initial;
  align-items: center;
  align-self: center;
  justify-content: flex-start;

  height: 100%;
  min-height: 600px;

  justify-self: center;
}

.EtzMobileSection_title {
  color: var(--secondary);
}

.EtzMobileSection_subTitle {
  width: 315px;
}

.EtzMobileSection_textContainer {
  padding: 30px 0;
}

.EtzMobileSection_text {
  margin-bottom: 16px;
}

.EtzMobileSection_button {
  display: flex;
  align-items: center;

  margin-top: 36px;
}

.EtzMobileSection_arrow {
  padding: 1px 0 0 16px;
}

.EtzMobileSection_description {
  min-width: 540px;
  max-width: 540px;
}

.EtzMobileSection_pattern {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;

  transform: translate(-50%, -50%);
}
