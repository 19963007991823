.FAQSection {
  display: flex;
  overflow: initial !important;
  align-items: center;
  justify-content: center;

  margin-top: var(--section-margin-top);
}

.FAQSection_title {
  text-align: center;

  color: var(--secondary);
}

.FAQSection_subtitle {
  text-align: center;
}

.FAQSection_accordionBlock {
  display: flex;
  flex-direction: column;
}

.FAQSection_accordion {
  position: relative;

  overflow: hidden;
  flex: 1;

  margin-bottom: 32px;

  cursor: pointer;
  /* transition: all 400ms ease; */

  border-radius: 20px;
  /* background: var(--primary-05); */
}

.FAQSection_accordion__while {
  border: 1px solid var(--primary-50);
}

.FAQSection_accordion__dark {
  border: 1px solid transparent;
}

.FAQSection_accordionSummary {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 23px 41px;

  transition: all 500ms ease-out;

  color: var(--primary);
  background: var(--primary-05);
}

.FAQSection_accordionSummary .FAQSection_arrowIcon {
  transition: transform 300ms ease;
}

.FAQSection_accordionSummary__open .FAQSection_arrowIcon {
  transform: scale(1, -1);
}

.FAQSection_accordionColumns {
  margin-bottom: 24px;

  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 64px;
  -moz-column-gap: 64px;
  column-gap: 64px;
}

.FAQSection_accordionDetails {
  display: block;
  overflow: hidden;

  max-height: 0;
  padding: 0 42px;

  transition: all 500ms ease-out;

  background: var(--primary-05);
}

.FAQSection_accordion__while .FAQSection_accordionSummary,
.FAQSection_accordion__while .FAQSection_accordionDetails {
  background: var(--bright);
}
.FAQSection_accordion__while .FAQSection_accordionDetails {
  padding-bottom: 13px;
}

.FAQSection_accordion__dark .FAQSection_accordionSummary,
.FAQSection_accordion__dark .FAQSection_accordionDetails {
  background: var(--primary-05);
}

.FAQSection_accordionDetailsItem {
  display: block;

  width: 100%;
  margin-bottom: 24px;

  transition: all 100ms ease-in-out;

  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  page-break-inside: avoid;
}

.FAQSection_accordionDetailsItem__50 {
  flex-basis: 50%;
  flex-grow: 0;

  margin-right: 64px;
}

.FAQSection_accordionDetailsItem__50:nth-child(2n) {
  margin-right: 0;
}

.FAQSection_accordionDetailsTitle {
  display: block;
}

.FAQSection_accordionDetailsDescription {
  color: var(--dark-50);
}

.FAQSection_arrowIcon {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

  width: 32px;
  height: 32px;
}
