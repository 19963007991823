.ChangePasswordPlaceholder {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;

  min-height: var(--vh-px);

  transition: opacity 400ms ease-in-out;

  background: var(--gradient-main);
}

.ChangePasswordPlaceholder_text {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: var(--bright);
  padding: 20px;
  margin-top: 30vh;
}

.ChangePasswordPlaceholder_leaves {
  position: absolute;
  top: 0;
  right: 0;

  pointer-events: none;
}

.ChangePasswordPlaceholder_leaves__svg {
  position: absolute;
  top: 0;
  right: 0;

  height: 100vh;
}

@media screen and (max-width: 768px) {
  .ChangePasswordPlaceholder_leaves {
    transform: scale(3) !important;
  }

  .ChangePasswordPlaceholder_leaves__svg {
    position: absolute;
    top: 0;
    right: 0;

    height: 100vh;
  }
}
