.BenefitsSection {
  display: flex;
  justify-content: center;

  box-sizing: content-box;
  height: 100vh;

  -webkit-overflow-scrolling: touch;
}

.BenefitsSection_container {
  align-self: center;

  height: 360px;

  justify-self: center;
}

.BenefitsSection_title {
  color: var(--secondary);
}

.BenefitsSection_viewSlider {
  position: relative;

  display: inline-block;
  overflow: hidden;

  width: 630px;
  margin-top: calc(72px - 36px);
  margin-left: -20px;
  padding: 0 20px;
}
.BenefitsSection_shadow {
  position: absolute;
  z-index: 1;
  right: -10px;

  width: 1px;
  height: 100%;

  background-color: var(--bright);
  box-shadow: 0 0 10px 20px var(--bright);
}

.BenefitsSection_shadow:first-child {
  left: -10px;
}
.BenefitsSection_slideContainer {
  position: relative;

  display: flex;
  flex-wrap: nowrap;

  width: 610px;
  min-height: 100px;
}
.BenefitsSection_slide {
  position: absolute;
  --position: 100vw;

  display: flex;
  flex-direction: column;

  min-width: 610px;
  padding-right: 10px;

  -webkit-transform: translateX(var(--position));
  -moz-transform: translateX(var(--position));
  -ms-transform: translateX(var(--position));
  -o-transform: translateX(var(--position));
  transform: translateX(var(--position));
}

.BenefitsSection_slide__shown {
  --position: 0;
}

.BenefitsSection_slide__hidden {
  --position: -100vw;
}

.BenefitsSection_text__gray {
  color: var(--dark-30);
}

.BenefitsSection_text__secondary {
  margin-top: 8px;

  color: var(--dark-30);
}

.BenefitsSection_slideImg {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  margin-bottom: 8px;
}
.BenefitsSection_dots {
  height: 10px;
  margin-top: 46px;
  margin-bottom: 20px;
}
.BenefitsSection_dot {
  display: inline-block;

  width: 10px;
  height: 10px;
  margin: 0 10px 0 10px;

  cursor: pointer;
  -webkit-transition: background-color 700ms cubic-bezier(0.45, 0, 0.55, 1);
  -moz-transition: background-color 700ms cubic-bezier(0.45, 0, 0.55, 1);
  -o-transition: background-color 700ms cubic-bezier(0.45, 0, 0.55, 1);
  transition: background-color 700ms cubic-bezier(0.45, 0, 0.55, 1);

  border-radius: 50%;
  background-color: var(--dark-30);
}

[data-status="active"] {
  background-color: var(--primary);
}

.BenefitsSection_dot:first-child {
  margin-left: 0;
}

.BenefitsSection_dot:last-child {
  margin-right: 0;
}
