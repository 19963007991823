/* src/styles/components/ira-calculator/ira-calculator.css */

/* General styles for all pages */
.CryptoRothIRACalculator_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.CryptoRothIRACalculator_container {
  width: 80%;
  max-width: 800px;
  padding: 20px;
  background-color: white;
  margin: 20px auto;
}

.CryptoRothIRACalculator_card {
  background-color: white;
  padding: 20px;
}

.CryptoRothIRACalculator_title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
  color: #333;
}

.CryptoRothIRACalculator_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 90px;
  justify-content: center;
}

.CryptoRothIRACalculator_inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.CryptoRothIRACalculator_label {
  margin-bottom: 10px;
  font-weight: 400;
}

.CryptoRothIRACalculator_input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 9px;
  width: 100%;
}

.CryptoRothIRACalculator_input.no-arrows {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.CryptoRothIRACalculator_input.no-arrows::-webkit-outer-spin-button,
.CryptoRothIRACalculator_input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.CryptoRothIRACalculator_buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.CryptoRothIRACalculator_button {
  background-color: #2991ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 9px;
  cursor: pointer;
  font-size: 16px;
  width: auto;
}

.CryptoRothIRACalculator_button:hover {
  background-color: #007bff;
}

.CryptoRothIRACalculator_result {
  margin-top: 20px;
  padding: 15px;
  background-color: #f4f7ff;
  border-radius: 4px;
}

.CryptoRothIRACalculator_resultTitle {
  font-size: 18px;
  margin-bottom: 10px;
}

#rothSavings {
  font-weight: bold;
}

/* Homepage-specific styles */
.homepage-calculator .CryptoRothIRACalculator_wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0; /* Remove horizontal padding */
  background-color: rgba(
    255,
    0,
    0,
    0.1
  ); /* Temporary background color for debugging */
}

.homepage-calculator .CryptoRothIRACalculator_container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  background-color: transparent;
  margin: 0;
}

.homepage-calculator .CryptoRothIRACalculator_card {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}

.homepage-calculator .CryptoRothIRACalculator_title {
  font-size: 32px !important; /* Adjust to match other section titles */
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--dark-100, #000000);
}

.homepage-calculator .CryptoRothIRACalculator_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.homepage-calculator .CryptoRothIRACalculator_column {
  width: 100%;
}

.homepage-calculator .CryptoRothIRACalculator_inputGroup {
  margin-bottom: 20px;
}

.homepage-calculator .CryptoRothIRACalculator_label {
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-70, #4d4d4d);
}

.homepage-calculator .CryptoRothIRACalculator_input {
  font-size: 16px;
  padding: 12px;
  border: 1px solid var(--dark-20, #cccccc);
  border-radius: 8px;
}

.homepage-calculator .CryptoRothIRACalculator_buttonWrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.homepage-calculator .CryptoRothIRACalculator_button {
  font-size: 18px;
  font-weight: 500;
  padding: 12px 24px;
  background-color: var(--primary-color, #2991ff);
  border-radius: 8px;
}

.homepage-calculator .CryptoRothIRACalculator_result {
  margin-top: 30px;
  background-color: var(--light-bg, #f4f7ff);
  border-radius: 8px;
  padding: 20px;
}

.homepage-calculator .CryptoRothIRACalculator_resultTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--dark-100, #000000);
}

.homepage-calculator #capitalGains,
.homepage-calculator #taxRate,
.homepage-calculator #rothSavings {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--dark-70, #4d4d4d);
}

.homepage-calculator #rothSavings {
  font-weight: 600;
  color: var(--dark-100, #000000);
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .CryptoRothIRACalculator_content {
    grid-template-columns: 1fr; /* Stack vertically on mobile */
    gap: 4px;
  }

  .CryptoRothIRACalculator_wrapper {
    padding: 20px 15px; /* Adjust padding for mobile */
  }

  .CryptoRothIRACalculator_container {
    width: 100%;
    padding: 0;
  }

  .CryptoRothIRACalculator_title {
    font-size: 24px !important; /* Smaller font size for mobile */
  }

  .CryptoRothIRACalculator_inputGroup {
    margin-bottom: 15px;
  }

  .CryptoRothIRACalculator_button {
    width: 100%; /* Full width button on mobile */
  }
}
