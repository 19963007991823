.LogosSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.LogosSection_block {
  display: flex;
  flex-direction: row; /* Keep text and logos in the same row */
  align-items: center; /* Vertically center content */
  justify-content: space-between; /* Distribute space evenly */
  width: 100%;
  max-width: 1200px; /* Optional: Limit the width to avoid stretching */
  gap: 0px; /* Reduce gap to bring text and logos closer together */
  margin-bottom: 80px;
}

.LogosSection_description {
  flex: 1; /* Allow description to take its share of space */
  text-align: left; /* Align the text to the left */
}

.LogosSection_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  margin: 0;
  max-width: 280px; /* Reduce max-width to better control line breaks */
  white-space: normal; /* Allow text wrapping */
}

.LogosSection_logos {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Space out logos evenly */
  flex: 1; /* Logos also take their share of space */
  gap: 150px; /* Adjust spacing between logos */
}

.logo {
  height: 35px; /* Adjust the logo size to be proportional to the text */
  opacity: 1.5;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.1);
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .LogosSection_block {
    flex-direction: column; /* Stack text and logos vertically on mobile */
    gap: 20px; /* Add spacing between text and logos */
  }

  .LogosSection_text {
    text-align: center; /* Center text on mobile */
    max-width: 100%; /* Allow text to take the full width */
    margin-bottom: 10px; /* Add some space below the text */
  }

  .LogosSection_logos {
    gap: 30px; /* Reduce spacing between logos on mobile */
    justify-content: center; /* Center logos on smaller screens */
  }

  .logo {
    height: 25px; /* Scale down logos on mobile */
  }
}
