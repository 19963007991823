.ContactUsSection {
  display: flex;
  justify-content: center;

  height: 675px;
  margin-top: var(--section-margin-top);
  padding: 60px 0;
}

.ContactUsSection_card {
  position: relative;

  display: flex;

  width: 1200px;
  height: 560px;
  padding: 60px 72px;

  border-radius: 20px;
  background: var(--gradient-main);
}

.ContactUsSection_formBlock {
  flex-basis: 620px;
  flex-grow: 0;

  max-width: 70%;
}

.ContactUsSection_title {
  display: block;

  margin-bottom: 16px;

  color: var(--bright);
}

.ContactUsSection_subtitle {
  display: block;

  margin-bottom: 32px;

  color: var(--bright);
}

.ContactUsSection_form {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  max-width: 550px;
}

.ContactUsSection_form input,
.ContactUsSection_form textarea {
  font-family: "Rubik";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
}

.ContactUsSection_form input {
  width: 100%;
  padding: 16px;
}

.ContactUsSection_input__half {
  flex-basis: 265px;
  flex-grow: 0;

  min-width: 265px;
  margin-bottom: 16px;
}

.ContactUsSection_input__3Quarters {
  flex-basis: 397px;
  flex-grow: 0;

  min-width: 397px;
  margin-bottom: 16px;
}
.ContactUsSection_input__1Quarters {
  flex-basis: 133px;
  flex-grow: 0;

  min-width: 133px;
  margin-bottom: 16px;
}

.ContactUsSection_input__left {
  margin-right: 8px;
}

.ContactUsSection_input__right {
  margin-left: 8px;
}

.ContactUsSection_input__error {
  border: 1px solid var(--negative);
}

.ContactUsSection_textarea {
  width: 546px;
  height: 88px;
}

.ContactUsSection_textarea textarea {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.ContactUsSection_peopleImgBlock {
  flex-basis: 30%;
  flex-grow: 0;

  max-width: 30%;
}

.ContactUsSection_peopleImg {
  position: absolute;
  top: -39px;
  right: 61px;

  width: 422px;
  height: 675px;
}

.ContactUsSection_formFooter {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;

  margin-top: 32px;
}

.ContactUsSection_formConfirmBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: 27px;
}

.ContactUsSection_checkbox {
  position: absolute;
  z-index: -1;

  cursor: pointer;

  opacity: 0;
}

.ContactUsSection_checkbox + label {
  display: inline-flex;
  align-items: flex-start;

  min-width: 24px;
  height: 24px;
  margin-right: 12px;

  user-select: none;
}

.ContactUsSection_checkbox + label::before {
  display: inline-block;
  flex-basis: 24px;
  flex-grow: 0;

  min-width: 24px;
  height: 24px;

  content: "";
  cursor: pointer;

  border: 1px solid transparent;
  border-radius: 6px;
  background-color: var(--bright);
  background-repeat: no-repeat;
  background-position: center center;
}

.ContactUsSection_checkbox.ContactUsSection_checkbox__error + label::before {
  border-color: var(--negative);
}

.ContactUsSection_checkbox:focus-visible + label::before {
  border: 1px solid rgba(34, 109, 255, 0.2);
}

.ContactUsSection_checkbox:checked + label::before {
  background-image: url("../../../assets/check.svg");
}

.ContactUsSection_rules {
  line-height: 30px;

  height: 100%;

  vertical-align: middle;

  color: var(--bright);
}

.ContactUsSection_submit {
  min-width: 164px;
}

.ContactUsSection__dropdown {
  width: 100%;
}
