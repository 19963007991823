.Notification {
  z-index: 5000;
  flex-direction: column;
  align-items: flex-end;
  padding: 32px;
  display: flex;
  position: fixed;
  bottom: .5rem;
  right: 1rem;
  overflow-x: hidden;
}

.Notification_messageContainer {
  visibility: hidden;
  width: 340px;
  opacity: 0;
  background: var(--bright);
  border-radius: 10px;
  justify-content: space-between;
  align-items: flex-start;
  margin: 8px 0;
  padding: 14px 14px 14px 24px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  transition-property: transform, opacity, visibility;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
  display: flex;
  transform: translateX(100%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
}

.Notification_content {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.Notification_messageContainer__error {
  color: var(--negative);
}

.Notification_messageContainer__success {
  color: var(--bright);
  background: var(--primary);
}

.Notification_exit {
  width: 14px;
  height: 14px;
  cursor: pointer;
  order: 1;
  margin: 5px 5px 0 21px;
}

.Notification_messageContainer.Notification_messageContainer__received {
  visibility: visible;
  opacity: 1;
  transform: translateX(0%);
}

.Footer {
  margin-top: var(--section-margin-top);
  background-color: var(--primary-05);
  flex-flow: column wrap;
  align-items: center;
  display: flex;
}

.Footer_nav {
  width: 1200px;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 48px;
  display: flex;
}

.Footer_descriptionBlock {
  width: 1200px;
  margin-top: 19px;
  margin-bottom: 17px;
}

.Footer_nav a {
  margin: 0 24px;
}

.Footer_nav a:first-child {
  margin-left: 0;
}

.Footer_nav a:last-child {
  margin-right: 0;
}

.Footer_divider {
  width: 100vw;
  min-height: 1px;
  max-height: 1px;
  opacity: .2;
  background-color: var(--dark-50);
}

.Footer_privacy {
  width: 1200px;
  color: var(--dark-50);
  justify-content: space-between;
  margin: 25px 0 19px;
  display: flex;
}

.Footer_address {
  width: 1200px;
  color: var(--dark-50);
}

.Footer_verticalDivider {
  margin: 0 10px;
}

.Footer_description {
  color: var(--dark-50);
  display: block;
}

.Footer_description:first-child {
  display: initial;
}

.Footer_description__hidden {
  display: none;
}

.Footer_checkboxContainer {
  position: relative;
}

.Footer_checkbox {
  z-index: -1;
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.Footer_checkbox + label {
  width: 100px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--primary);
  align-items: flex-start;
}

.Footer_checkbox:checked + label {
  display: none;
}

.Footer_checkbox:checked ~ .Footer_description__hidden {
  display: block;
}

.ContactUsSection {
  height: 675px;
  margin-top: var(--section-margin-top);
  justify-content: center;
  padding: 60px 0;
  display: flex;
}

.ContactUsSection_card {
  width: 1200px;
  height: 560px;
  background: var(--gradient-main);
  border-radius: 20px;
  padding: 60px 72px;
  display: flex;
  position: relative;
}

.ContactUsSection_formBlock {
  max-width: 70%;
  flex-grow: 0;
  flex-basis: 620px;
}

.ContactUsSection_title {
  color: var(--bright);
  margin-bottom: 16px;
  display: block;
}

.ContactUsSection_subtitle {
  color: var(--bright);
  margin-bottom: 32px;
  display: block;
}

.ContactUsSection_form {
  width: 100%;
  max-width: 550px;
  flex-wrap: wrap;
  display: flex;
}

.ContactUsSection_form input, .ContactUsSection_form textarea {
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.ContactUsSection_form input {
  width: 100%;
  padding: 16px;
}

.ContactUsSection_input__half {
  min-width: 265px;
  flex-grow: 0;
  flex-basis: 265px;
  margin-bottom: 16px;
}

.ContactUsSection_input__3Quarters {
  min-width: 397px;
  flex-grow: 0;
  flex-basis: 397px;
  margin-bottom: 16px;
}

.ContactUsSection_input__1Quarters {
  min-width: 133px;
  flex-grow: 0;
  flex-basis: 133px;
  margin-bottom: 16px;
}

.ContactUsSection_input__left {
  margin-right: 8px;
}

.ContactUsSection_input__right {
  margin-left: 8px;
}

.ContactUsSection_input__error {
  border: 1px solid var(--negative);
}

.ContactUsSection_textarea {
  width: 546px;
  height: 88px;
}

.ContactUsSection_textarea textarea {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.ContactUsSection_peopleImgBlock {
  max-width: 30%;
  flex-grow: 0;
  flex-basis: 30%;
}

.ContactUsSection_peopleImg {
  width: 422px;
  height: 675px;
  position: absolute;
  top: -39px;
  right: 61px;
}

.ContactUsSection_formFooter {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  display: flex;
}

.ContactUsSection_formConfirmBlock {
  height: 27px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.ContactUsSection_checkbox {
  z-index: -1;
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.ContactUsSection_checkbox + label {
  min-width: 24px;
  height: 24px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: flex-start;
  margin-right: 12px;
  display: inline-flex;
}

.ContactUsSection_checkbox + label:before {
  min-width: 24px;
  height: 24px;
  content: "";
  cursor: pointer;
  background-color: var(--bright);
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
  flex-grow: 0;
  flex-basis: 24px;
  display: inline-block;
}

.ContactUsSection_checkbox.ContactUsSection_checkbox__error + label:before {
  border-color: var(--negative);
}

.ContactUsSection_checkbox:focus-visible + label:before {
  border: 1px solid rgba(34, 109, 255, .2);
}

.ContactUsSection_checkbox:checked + label:before {
  background-image: url("check.c918d634.svg");
}

.ContactUsSection_rules {
  height: 100%;
  vertical-align: middle;
  color: var(--bright);
  line-height: 30px;
}

.ContactUsSection_submit {
  min-width: 164px;
}

.ContactUsSection__dropdown {
  width: 100%;
}

.FAQSection {
  margin-top: var(--section-margin-top);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: initial !important;
}

.FAQSection_title {
  text-align: center;
  color: var(--secondary);
}

.FAQSection_subtitle {
  text-align: center;
}

.FAQSection_accordionBlock {
  flex-direction: column;
  display: flex;
}

.FAQSection_accordion {
  cursor: pointer;
  border-radius: 20px;
  flex: 1;
  margin-bottom: 32px;
  position: relative;
  overflow: hidden;
}

.FAQSection_accordion__while {
  border: 1px solid var(--primary-50);
}

.FAQSection_accordion__dark {
  border: 1px solid rgba(0, 0, 0, 0);
}

.FAQSection_accordionSummary {
  color: var(--primary);
  background: var(--primary-05);
  justify-content: space-between;
  align-items: center;
  padding: 23px 41px;
  transition: all .5s ease-out;
  display: flex;
}

.FAQSection_accordionSummary .FAQSection_arrowIcon {
  transition: transform .3s;
}

.FAQSection_accordionSummary__open .FAQSection_arrowIcon {
  transform: scale(1, -1);
}

.FAQSection_accordionColumns {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 64px;
  -moz-column-gap: 64px;
  column-gap: 64px;
  margin-bottom: 24px;
}

.FAQSection_accordionDetails {
  max-height: 0;
  background: var(--primary-05);
  padding: 0 42px;
  transition: all .5s ease-out;
  display: block;
  overflow: hidden;
}

.FAQSection_accordion__while .FAQSection_accordionSummary, .FAQSection_accordion__while .FAQSection_accordionDetails {
  background: var(--bright);
}

.FAQSection_accordion__while .FAQSection_accordionDetails {
  padding-bottom: 13px;
}

.FAQSection_accordion__dark .FAQSection_accordionSummary, .FAQSection_accordion__dark .FAQSection_accordionDetails {
  background: var(--primary-05);
}

.FAQSection_accordionDetailsItem {
  width: 100%;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  page-break-inside: avoid;
  margin-bottom: 24px;
  transition: all .1s ease-in-out;
  display: block;
}

.FAQSection_accordionDetailsItem__50 {
  flex-grow: 0;
  flex-basis: 50%;
  margin-right: 64px;
}

.FAQSection_accordionDetailsItem__50:nth-child(2n) {
  margin-right: 0;
}

.FAQSection_accordionDetailsTitle {
  display: block;
}

.FAQSection_accordionDetailsDescription {
  color: var(--dark-50);
}

.FAQSection_arrowIcon {
  width: 32px;
  height: 32px;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
}

.SupportedCryptoSection {
  justify-content: center;
  align-items: center;
  margin-top: 106px;
}

.SupportedCryptoSection_container {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  display: flex;
}

.SupportedCryptoSection_title {
  text-align: center;
  color: var(--primary);
}

.SupportedCryptoSection_subtitle {
  text-align: center;
}

.SupportedCryptoSection_searchBlock {
  justify-content: center;
  display: flex;
}

.SupportedCryptoSection_search {
  box-sizing: content-box;
  width: 790px;
  height: 80px;
  border: 1px solid var(--dark-30);
  background: var(--bright-50);
  border-radius: 20px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.SupportedCryptoSection_exit {
  cursor: pointer;
  position: absolute;
  top: 29px;
  right: 36px;
}

.SupportedCryptoSection_input {
  border: none;
  outline: none;
  padding: 2px 32px;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.SupportedCryptoSection_input::-ms-input-placeholder {
  color: var(--dark-50);
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.SupportedCryptoSection_input::placeholder {
  color: var(--dark-50);
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.SupportedCryptoSection_table {
  width: 1200px;
  height: 658px;
  background: var(--primary-05);
  border-radius: 20px;
  flex-flow: column wrap;
  margin-top: 12px;
  margin-bottom: 75px;
  padding: 23px 54px;
  display: flex;
}

.SupportedCryptoSection_item {
  min-width: 50%;
  min-height: 38px;
  color: var(--dark-30);
  align-items: center;
  margin: 15px 0;
  padding-left: 17px;
  display: flex;
}

.SupportedCryptoSection_numberCoin {
  min-width: 40px;
}

.SupportedCryptoSection_imgCoin {
  min-width: 38px;
  min-height: 38px;
  margin: 0 12px 0 9px;
}

.SupportedCryptoSection_nameCoin {
  color: var(--dark);
  margin-right: 12px;
}

.SupportedCryptoSection_buttonContainer {
  justify-content: center;
  margin-top: 34px;
  display: flex;
}

.SupportedCryptoSection_button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 50%;
  outline: none;
}

.SupportedCryptoSection_button__active {
  color: var(--text-white);
  background-color: var(--primary);
}

.SupportedCryptoTable_textEmprty {
  text-align: center;
  margin: auto 0;
}

.SupportedCryptoSection_placeholder {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.SupportedCryptoSection_placeholderPerson {
  width: 60px;
  margin-right: 34px;
  transform: rotate(-5deg);
}

.SupportedCryptoSection_placeholderCloud {
  max-width: 401px;
  max-height: 100%;
  background: var(--primary-05);
  border-radius: 20px;
  padding: 22px 25px;
  display: block;
  position: relative;
}

.SupportedCryptoSection_placeholderCloud span {
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  line-clamp: 15;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.SupportedCryptoSection_placeholderCloud:after {
  width: 21px;
  height: 17px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.0876 0.28125C13.7384 3.38263 5.51406 7.95968 0.0876465 13.9196C10.4715 11.0128 17.2294 13.9606 21.0876 17.6859V0.28125Z' fill='%23226DFF' fill-opacity='0.05'/%3E%3C/svg%3E%0A");
  display: block;
  position: absolute;
  top: 50%;
  left: -21px;
  transform: translateY(-50%);
}

.EtzMobileSection {
  height: 100%;
  padding-top: var(--section-margin-top);
  --coin-position: 1px;
  justify-content: center;
}

.EtzMobileSection_mobiles {
  transform: scale(clamp(.5, calc(var(--vh)  / 1038), 1) );
  justify-content: center;
  display: flex;
  position: relative;
}

.EtzMobileSection_img {
  height: 1038px;
}

.EtzMobileSection_coin {
  z-index: 1;
  --name: pulse;
  --time-function: ease-in-out;
  position: absolute;
}

.EtzMobileSection_ada {
  top: calc(613px + var(--coin-position) );
  animation: var(--name) 4900ms var(--time-function) infinite;
  left: -21px;
}

.EtzMobileSection_avax {
  top: calc(340px + var(--coin-position) );
  animation: var(--name) 5200ms var(--time-function) infinite;
  left: 687px;
}

.EtzMobileSection_bnb {
  top: calc(220px + var(--coin-position) );
  animation: var(--name) 4800ms 40ms var(--time-function) infinite;
  left: -35px;
}

.EtzMobileSection_btc {
  top: calc(280px + var(--coin-position) );
  animation: var(--name) 4700ms 10ms var(--time-function) infinite;
  left: 390px;
}

.EtzMobileSection_eth {
  right: 246px;
  bottom: calc(312px - var(--coin-position) );
  animation: var(--name) 5000ms 20ms var(--time-function) infinite;
}

.EtzMobileSection_ftm {
  top: calc(163px + var(--coin-position) );
  animation: var(--name) 4500ms 10ms var(--time-function) infinite;
  left: -120px;
}

.EtzMobileSection_sol {
  top: calc(70px + var(--coin-position) );
  animation: var(--name) 4000ms 5ms var(--time-function) infinite;
  right: 345px;
}

.EtzMobileSection_usdc {
  bottom: calc(101px - var(--coin-position) );
  animation: var(--name) 5500ms var(--time-function) infinite;
  left: 369px;
}

.EtzMobileSection_block {
  overflow: initial;
  height: 100%;
  min-height: 600px;
  justify-content: flex-start;
  align-self: center;
  justify-self: center;
  align-items: center;
  display: flex;
  position: relative;
}

.EtzMobileSection_title {
  color: var(--secondary);
}

.EtzMobileSection_subTitle {
  width: 315px;
}

.EtzMobileSection_textContainer {
  padding: 30px 0;
}

.EtzMobileSection_text {
  margin-bottom: 16px;
}

.EtzMobileSection_button {
  align-items: center;
  margin-top: 36px;
  display: flex;
}

.EtzMobileSection_arrow {
  padding: 1px 0 0 16px;
}

.EtzMobileSection_description {
  min-width: 540px;
  max-width: 540px;
}

.EtzMobileSection_pattern {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.ForEmployersSection {
  overflow: initial;
  box-sizing: border-box;
  height: 552px;
  margin-top: var(--section-margin-top);
  justify-content: center;
  align-items: center;
}

.ForEmployersSection_container {
  align-self: center;
  justify-self: center;
}

.ForEmployersSection_title {
  color: var(--secondary);
}

.ForEmployersSection_steps {
  width: 1200px;
  height: 400px;
  position: relative;
}

.ForEmployersSection_step {
  width: 380px;
  background: var(--primary-05);
  border-radius: 20px;
  padding: 66px 25px 28px 30px;
  position: absolute;
}

.ForEmployersSection_step__first {
  bottom: -17px;
  left: -4px;
}

.ForEmployersSection_step__second {
  bottom: 90px;
  left: 410px;
}

.ForEmployersSection_step__third {
  top: 65px;
  right: 0;
}

.ForEmployersSection_chart {
  position: absolute;
  top: -71px;
  left: -21px;
}

.ForEmployersSection_arrow {
  position: absolute;
}

.ForEmployersSection_arrow__first {
  top: -37px;
  right: -21px;
}

.ForEmployersSection_arrow__second {
  top: 26px;
  right: -29px;
  transform: scale(1, -1)rotate(45deg);
}

.ForEmployersSection_description {
  opacity: .5;
  padding-top: 8px;
  display: block;
}

.BenefitsSection {
  box-sizing: content-box;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  justify-content: center;
  display: flex;
}

.BenefitsSection_container {
  height: 360px;
  align-self: center;
  justify-self: center;
}

.BenefitsSection_title {
  color: var(--secondary);
}

.BenefitsSection_viewSlider {
  width: 630px;
  margin-top: 36px;
  margin-left: -20px;
  padding: 0 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.BenefitsSection_shadow {
  z-index: 1;
  width: 1px;
  height: 100%;
  background-color: var(--bright);
  box-shadow: 0 0 10px 20px var(--bright);
  position: absolute;
  right: -10px;
}

.BenefitsSection_shadow:first-child {
  left: -10px;
}

.BenefitsSection_slideContainer {
  width: 610px;
  min-height: 100px;
  flex-wrap: nowrap;
  display: flex;
  position: relative;
}

.BenefitsSection_slide {
  --position: 100vw;
  min-width: 610px;
  -webkit-transform: translateX(var(--position) );
  -moz-transform: translateX(var(--position) );
  -ms-transform: translateX(var(--position) );
  -o-transform: translateX(var(--position) );
  transform: translateX(var(--position) );
  flex-direction: column;
  padding-right: 10px;
  display: flex;
  position: absolute;
}

.BenefitsSection_slide__shown {
  --position: 0;
}

.BenefitsSection_slide__hidden {
  --position: -100vw;
}

.BenefitsSection_text__gray {
  color: var(--dark-30);
}

.BenefitsSection_text__secondary {
  color: var(--dark-30);
  margin-top: 8px;
}

.BenefitsSection_slideImg {
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.BenefitsSection_dots {
  height: 10px;
  margin-top: 46px;
  margin-bottom: 20px;
}

.BenefitsSection_dot {
  width: 10px;
  height: 10px;
  cursor: pointer;
  -o-transition: background-color 700ms cubic-bezier(.45, 0, .55, 1);
  background-color: var(--dark-30);
  border-radius: 50%;
  margin: 0 10px;
  transition: background-color .7s cubic-bezier(.45, 0, .55, 1);
  display: inline-block;
}

[data-status="active"] {
  background-color: var(--primary);
}

.BenefitsSection_dot:first-child {
  margin-left: 0;
}

.BenefitsSection_dot:last-child {
  margin-right: 0;
}

.HeaderMenu_menu__mobile, .HeaderMenu_entryIconDark {
  display: none;
}

.HeaderMenu_logoLight {
  display: flex;
}

.HeaderMenu_logoDark, .HeaderMenu_burgerBlock, .HeaderMenu_linksBlockMobile, .HeaderMenu_overflow {
  display: none;
}

.HeaderMenu_menu {
  position: initial;
  z-index: var(--z-index-menu);
  height: var(--height-menu);
  color: var(--bright);
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  display: flex;
  left: 0;
  right: 0;
}

.HeaderMenu_menu__sticky {
  color: var(--text-black);
  margin-top: 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
}

.HeaderMenu_menu__sticky .HeaderMenu_logoDark {
  display: flex;
}

.HeaderMenu_menu__sticky .HeaderMenu_logoLight {
  display: none;
}

.HeaderMenu_menu__sticky .HeaderMenu_entryIconDark {
  display: initial;
}

.HeaderMenu_menu__sticky .HeaderMenu_entryIconLight {
  display: none;
}

.HeaderMenu_menuContainer {
  z-index: var(--z-index-menu);
  width: 1200px;
  align-items: center;
  display: flex;
}

.HeaderMenu_menu__sticky.HeaderMenu_menu {
  color: var(--text-black);
  background: var(--bright);
  animation: show-menu .1s linear;
  position: fixed;
  top: 0;
}

.HeaderMenu_menu__stickyHide.HeaderMenu_menu {
  background: none;
}

.HeaderMenu_initial {
  flex-wrap: nowrap;
  flex: 1;
  justify-content: end;
  padding-left: 50px;
  display: flex;
}

.HeaderMenu_headerGetStarted {
  border-color: rgba(0, 0, 0, 0);
  padding: 12px 26px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.HeaderMenu_links {
  margin-left: 250px;
  position: relative;
}

#HeaderMenu_links {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.HeaderMenu_links .HeaderMenu_link {
  cursor: pointer;
  white-space: nowrap;
  letter-spacing: .25px;
  margin-right: 40px;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  display: inline-block;
  position: relative;
}

.HeaderMenu_menu__sticky .HeaderMenu_links .HeaderMenu_link .HeaderMenu_linkText__black {
  color: var(--text-black);
  transition: all .3s linear;
  display: inline-block;
}

.HeaderMenu_links .HeaderMenu_link .HeaderMenu_linkText__black {
  opacity: 1;
  color: var(--bright);
  display: inline-block;
  transform: rotateX(0deg)translateY(0);
}

.HeaderMenu_links .HeaderMenu_link .HeaderMenu_linkText__primary {
  opacity: 0;
  transition: all .3s linear;
  display: inline-block;
  position: absolute;
}

.HeaderMenu_link.HeaderMenu_link__active .HeaderMenu_linkText__black {
  opacity: 0;
  position: absolute;
  transform: rotateX(90deg)translateY(-10px);
}

.HeaderMenu_link.HeaderMenu_link__deactive .HeaderMenu_linkText__black {
  opacity: 1;
  position: relative;
  transform: rotateX(0deg)translateY(0);
}

.HeaderMenu_link.HeaderMenu_link__active .HeaderMenu_linkText__primary {
  opacity: 1;
  color: var(--primary);
  position: relative;
}

.HeaderMenu_link.HeaderMenu_link__deactive .HeaderMenu_linkText__primary {
  opacity: 0;
  color: var(--primary);
  display: inline-block;
  position: absolute;
  left: 0;
  transform: rotateX(-90deg)translateY(10px);
}

.HeaderMenu_links .HeaderMenu_link:first-child {
  margin-left: 0;
}

.HeaderMenu_links .HeaderMenu_link:last-child {
  margin-right: 0;
}

.HeaderMenu_link__highlight {
  cursor: pointer;
  white-space: nowrap;
  letter-spacing: .25px;
  color: var(--highlight);
  background-color: var(--dark-10);
  border-radius: 16px;
  margin-left: -1px;
  padding: 2px 16px;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  display: inline;
}

.HeaderMenu_menu__sticky .HeaderMenu_link__highlight {
  color: var(--positive);
  background-color: var(--highlight-20);
}

.HeaderMenu_menu__sticky .HeaderMenu_buttonLink {
  color: var(--primary);
}

.HeaderMenu_buttonLink {
  cursor: pointer;
  white-space: nowrap;
  border-radius: .8em;
  flex-wrap: nowrap;
  align-items: center;
  padding: .8em;
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.HeaderMenu_buttonIcon {
  margin-right: 12px;
}

.HeaderMenu_dashContainer {
  box-sizing: border-box;
  min-height: 4px;
  position: absolute;
  bottom: -38px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.HeaderMenu_dash {
  width: 70px;
  min-height: 4px;
  background-color: var(--bright);
  transition: width .15s linear, transform .3s linear;
  transform: translateX(-80px);
}

.HeaderMenu_menu__sticky .HeaderMenu_dash {
  background-color: var(--primary);
}

.HeaderMenu_headerGetFreeDemo {
  margin-right: 16px;
  padding: 12px 26px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.HeaderMenu_links {
  align-items: center;
  display: flex;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropbtn {
  color: var(--bright);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.dropdown-content {
  z-index: 1;
  min-width: 160px;
  background-color: #2479fe;
  display: none;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .1);
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: var(--primary);
  background-color: #fff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.HeaderMenu_menu__sticky .dropbtn {
  color: var(--text-black);
}

:root {
  --height-menu: 106px;
}

.HeaderSection {
  box-sizing: content-box;
  height: 100vh;
  color: var(--text-white);
  background: var(--gradient-main);
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.HeaderSection_menu {
  min-height: 106px;
  margin: 29px 0;
}

.HeaderSection_welcomeSection {
  overflow: hidden;
}

.HeaderSection_main {
  height: 100vh;
  justify-self: center;
  align-items: center;
  margin-top: -135px;
  display: flex;
}

.HeaderSection_container {
  z-index: 10;
  padding-top: 72px;
}

.HeaderSection_overflow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.HeaderSection_welcome {
  width: 688px;
  flex-direction: column;
  margin-right: -210px;
  display: flex;
  position: relative;
}

.HeaderSection_title {
  width: 688px;
  color: var(--text-white);
  margin-bottom: 33px;
  font-size: 48px;
  font-weight: 500;
  line-height: 52px;
  display: block;
}

.HeaderSection_description {
  width: 649px;
  letter-spacing: .25px;
  color: var(--text-white);
  font-size: 18px;
  font-style: normal;
  line-height: 30px;
  display: block;
}

.HeaderSection_mobile {
  z-index: var(--z-index-front-img);
  position: absolute;
  top: 0;
  right: 40px;
}

.HeaderSection_mobile img {
  height: 700px;
}

.HeaderSection_triangle {
  height: 370px;
  background: var(--bright);
  -webkit-clip-path: polygon(100% 0%, 0% 73%, 100% 100%);
  clip-path: polygon(100% 0%, 0% 73%, 100% 100%);
  position: absolute;
  bottom: 0;
  left: -50%;
  right: 0;
  transform: translate(0, 50%);
}

.HeaderSection_buttons {
  height: 50px;
  margin-top: 60px;
  display: flex;
}

.HeaderSection_buttons a {
  width: 205px;
}

.HeaderSection_buttons a:last-child {
  margin-left: 20px;
}

.HeaderSection_getStartedButton {
  padding: 10px 53px;
}

.HeaderSection_downloadButton {
  margin-left: 12px;
  padding: 10px 43px;
}

.ChangePasswordPlaceholder {
  z-index: 1000;
  min-height: var(--vh-px);
  background: var(--gradient-main);
  justify-content: center;
  transition: opacity .4s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ChangePasswordPlaceholder_text {
  text-align: center;
  color: var(--bright);
  margin-top: 30vh;
  padding: 20px;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

.ChangePasswordPlaceholder_leaves {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
}

.ChangePasswordPlaceholder_leaves__svg {
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .ChangePasswordPlaceholder_leaves {
    transform: scale(3) !important;
  }

  .ChangePasswordPlaceholder_leaves__svg {
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.EtzMobileSection {
  height: 100%;
  padding-top: var(--section-margin-top);
  --coin-position: 1px;
  justify-content: center;
}

.EtzMobileSection_mobiles {
  transform: scale(clamp(.5, calc(var(--vh)  / 1038), 1) );
  justify-content: center;
  display: flex;
  position: relative;
}

.EtzMobileSection_img {
  height: 1038px;
}

.EtzMobileSection_coin {
  z-index: 1;
  --name: pulse;
  --time-function: ease-in-out;
  position: absolute;
}

.EtzMobileSection_ada {
  top: calc(613px + var(--coin-position) );
  animation: var(--name) 4900ms var(--time-function) infinite;
  left: -21px;
}

.EtzMobileSection_avax {
  top: calc(340px + var(--coin-position) );
  animation: var(--name) 5200ms var(--time-function) infinite;
  left: 687px;
}

.EtzMobileSection_bnb {
  top: calc(220px + var(--coin-position) );
  animation: var(--name) 4800ms 40ms var(--time-function) infinite;
  left: -35px;
}

.EtzMobileSection_btc {
  top: calc(280px + var(--coin-position) );
  animation: var(--name) 4700ms 10ms var(--time-function) infinite;
  left: 390px;
}

.EtzMobileSection_eth {
  right: 246px;
  bottom: calc(312px - var(--coin-position) );
  animation: var(--name) 5000ms 20ms var(--time-function) infinite;
}

.EtzMobileSection_ftm {
  top: calc(163px + var(--coin-position) );
  animation: var(--name) 4500ms 10ms var(--time-function) infinite;
  left: -120px;
}

.EtzMobileSection_sol {
  top: calc(70px + var(--coin-position) );
  animation: var(--name) 4000ms 5ms var(--time-function) infinite;
  right: 345px;
}

.EtzMobileSection_usdc {
  bottom: calc(101px - var(--coin-position) );
  animation: var(--name) 5500ms var(--time-function) infinite;
  left: 369px;
}

.EtzMobileSection_block {
  overflow: initial;
  height: 100%;
  min-height: 600px;
  justify-content: flex-start;
  align-self: center;
  justify-self: center;
  align-items: center;
  display: flex;
  position: relative;
}

.EtzMobileSection_title {
  color: var(--secondary);
}

.EtzMobileSection_subTitle {
  width: 315px;
}

.EtzMobileSection_textContainer {
  padding: 30px 0;
}

.EtzMobileSection_text {
  margin-bottom: 16px;
}

.EtzMobileSection_button {
  align-items: center;
  margin-top: 36px;
  display: flex;
}

.EtzMobileSection_arrow {
  padding: 1px 0 0 16px;
}

.EtzMobileSection_description {
  min-width: 540px;
  max-width: 540px;
}

.EtzMobileSection_pattern {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.founder-tax-chart .chartContainer {
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.founder-tax-chart .chartTitle {
  color: #2891ff;
  text-align: center;
  margin-bottom: 20px;
  margin-right: 340px;
  font-size: 1.5em;
  font-weight: bold;
}

.founder-tax-chart .chart {
  width: 100%;
  justify-content: space-around;
  display: flex;
}

.founder-tax-chart .bar {
  width: 240px;
  height: 600px;
  box-sizing: border-box;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.founder-tax-chart .bar.withETZ {
  background: linear-gradient(135deg, #2891ff 0%, #226dff 100%);
}

.founder-tax-chart .bar.withoutETZ {
  background: linear-gradient(135deg, #2891ff 0%, #226dff 100%);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.founder-tax-chart .barLabel {
  width: 100%;
  text-align: center;
  background-color: #f5f5f5;
  padding: 10px 0;
  font-size: 1.1em;
  font-weight: bold;
}

.founder-tax-chart .barSegment {
  text-align: center;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  position: relative;
}

.founder-tax-chart .bar.withoutETZ .barSegment.taxLosses {
  color: #2891ff;
  background: #fff;
  border-top: 2px dashed #2891ff;
  border-left: 2px dashed #2891ff;
  border-right: 2px dashed #2891ff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 3;
}

.founder-tax-chart .bar.withETZ .barSegment.after25Years {
  flex-grow: 4;
}

.founder-tax-chart .bar.withETZ .barSegment.afterExit {
  flex-grow: 1;
}

.founder-tax-chart .bar.withoutETZ .barSegment.after25Years {
  flex-grow: 2;
}

.founder-tax-chart .bar.withoutETZ .barSegment.afterExit {
  flex-grow: 1;
}

.founder-tax-chart .barSegment:not(:last-child):after {
  content: "";
  height: 2px;
  width: 100%;
  background: #fff;
  display: block;
  position: absolute;
  bottom: 0;
}

.founder-tax-chart figcaption {
  color: #666;
  margin-top: 20px;
  font-size: 1em;
}

.founderButton {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #2891ff;
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  padding: 12px 24px;
  font-size: 1em;
  font-weight: bold;
  display: inline-block;
}

.Button__primary {
  background-color: #2891ff;
}

.Button__primary:hover {
  background-color: #226dff;
}

@media (max-width: 767px) {
  .founder-tax-chart .chartTitle {
    text-align: center;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .founder-tax-chart .bar {
    height: 400px;
  }

  .founderButton {
    margin: 20px auto;
    display: block;
  }
}

.LogosSection {
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  display: flex;
}

.LogosSection_block {
  width: 100%;
  max-width: 1200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  margin-bottom: 80px;
  display: flex;
}

.LogosSection_description {
  text-align: left;
  flex: 1;
}

.LogosSection_text {
  color: #333;
  max-width: 280px;
  white-space: normal;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.LogosSection_logos {
  flex: 1;
  justify-content: space-around;
  align-items: center;
  gap: 150px;
  display: flex;
}

.logo {
  height: 35px;
  opacity: 1.5;
  transition: transform .3s;
}

.logo:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .LogosSection_block {
    flex-direction: column;
    gap: 20px;
  }

  .LogosSection_text {
    text-align: center;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .LogosSection_logos {
    justify-content: center;
    gap: 30px;
  }

  .logo {
    height: 25px;
  }
}

.LogoBar {
  justify-content: center;
  margin-top: 150px;
  margin-bottom: 50px;
  display: flex;
}

.LogoBar_logos {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 90px;
  display: flex;
}

.logo {
  max-height: 40px;
  transition: transform .3s;
}

.logo:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .LogoBar {
    margin-top: 80px;
    margin-bottom: 30px;
  }

  .LogoBar_logos {
    justify-content: center;
    gap: 30px;
  }

  .logo {
    max-height: 30px;
  }
}

.CryptoRothIRACalculator_wrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.CryptoRothIRACalculator_container {
  width: 80%;
  max-width: 800px;
  background-color: #fff;
  margin: 20px auto;
  padding: 20px;
}

.CryptoRothIRACalculator_card {
  background-color: #fff;
  padding: 20px;
}

.CryptoRothIRACalculator_title {
  text-align: left;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.CryptoRothIRACalculator_content {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 90px;
  display: grid;
}

.CryptoRothIRACalculator_inputGroup {
  flex-direction: column;
  margin-bottom: 15px;
  display: flex;
}

.CryptoRothIRACalculator_label {
  margin-bottom: 10px;
  font-weight: 400;
}

.CryptoRothIRACalculator_input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 9px;
  padding: 8px;
}

.CryptoRothIRACalculator_input.no-arrows {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
}

.CryptoRothIRACalculator_input.no-arrows::-webkit-outer-spin-button, .CryptoRothIRACalculator_input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.CryptoRothIRACalculator_buttonWrapper {
  justify-content: center;
  margin-top: 20px;
  display: flex;
}

.CryptoRothIRACalculator_button {
  color: #fff;
  cursor: pointer;
  width: auto;
  background-color: #2991ff;
  border: none;
  border-radius: 9px;
  padding: 10px 20px;
  font-size: 16px;
}

.CryptoRothIRACalculator_button:hover {
  background-color: #007bff;
}

.CryptoRothIRACalculator_result {
  background-color: #f4f7ff;
  border-radius: 4px;
  margin-top: 20px;
  padding: 15px;
}

.CryptoRothIRACalculator_resultTitle {
  margin-bottom: 10px;
  font-size: 18px;
}

#rothSavings {
  font-weight: bold;
}

.homepage-calculator .CryptoRothIRACalculator_wrapper {
  width: 100%;
  max-width: 1200px;
  background-color: rgba(255, 0, 0, .1);
  margin: 0 auto;
  padding: 40px 0;
}

.homepage-calculator .CryptoRothIRACalculator_container {
  width: 100%;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
}

.homepage-calculator .CryptoRothIRACalculator_card {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.homepage-calculator .CryptoRothIRACalculator_title {
  color: var(--dark-100, #000);
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 32px !important;
}

.homepage-calculator .CryptoRothIRACalculator_content {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.homepage-calculator .CryptoRothIRACalculator_column {
  width: 100%;
}

.homepage-calculator .CryptoRothIRACalculator_inputGroup {
  margin-bottom: 20px;
}

.homepage-calculator .CryptoRothIRACalculator_label {
  color: var(--dark-70, #4d4d4d);
  font-size: 16px;
  font-weight: 400;
}

.homepage-calculator .CryptoRothIRACalculator_input {
  border: 1px solid var(--dark-20, #ccc);
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
}

.homepage-calculator .CryptoRothIRACalculator_buttonWrapper {
  justify-content: flex-start;
  margin-top: 30px;
  display: flex;
}

.homepage-calculator .CryptoRothIRACalculator_button {
  background-color: var(--primary-color, #2991ff);
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
}

.homepage-calculator .CryptoRothIRACalculator_result {
  background-color: var(--light-bg, #f4f7ff);
  border-radius: 8px;
  margin-top: 30px;
  padding: 20px;
}

.homepage-calculator .CryptoRothIRACalculator_resultTitle {
  color: var(--dark-100, #000);
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
}

.homepage-calculator #capitalGains, .homepage-calculator #taxRate, .homepage-calculator #rothSavings {
  color: var(--dark-70, #4d4d4d);
  margin-bottom: 10px;
  font-size: 16px;
}

.homepage-calculator #rothSavings {
  color: var(--dark-100, #000);
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .CryptoRothIRACalculator_content {
    grid-template-columns: 1fr;
    gap: 4px;
  }

  .CryptoRothIRACalculator_wrapper {
    padding: 20px 15px;
  }

  .CryptoRothIRACalculator_container {
    width: 100%;
    padding: 0;
  }

  .CryptoRothIRACalculator_title {
    font-size: 24px !important;
  }

  .CryptoRothIRACalculator_inputGroup {
    margin-bottom: 15px;
  }

  .CryptoRothIRACalculator_button {
    width: 100%;
  }
}

.founder-tax-chart {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.chart-container {
  width: 100%;
  height: 275px;
  margin-bottom: 0;
}

.disclaimer {
  color: #666;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}

.EtzMobileSection_block {
  gap: 100px !important;
}

@media screen and (max-width: 768px) {
  .chart-container {
    height: 200px;
  }

  .EtzMobileSection_block {
    justify-content: flex-start !important;
    gap: 40px !important;
  }

  .EtzMobileSection {
    justify-content: center !important;
  }
}



/*# sourceMappingURL=index.016bfd18.css.map */
