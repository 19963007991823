.ForEmployersSection {
  overflow: initial;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 552px;
  margin-top: var(--section-margin-top);
}

.ForEmployersSection_container {
  align-self: center;

  justify-self: center;
}

.ForEmployersSection_title {
  color: var(--secondary);
}

.ForEmployersSection_steps {
  position: relative;

  width: 1200px;
  height: 400px;
}

.ForEmployersSection_step {
  position: absolute;

  width: 380px;
  padding: 66px 25px 28px 30px;

  border-radius: 20px;
  background: var(--primary-05);
}

.ForEmployersSection_step__first {
  bottom: -17px;
  left: -4px;
}

.ForEmployersSection_step__second {
  bottom: 90px;
  left: 410;
}

.ForEmployersSection_step__third {
  top: 65px;
  right: 0;
}

.ForEmployersSection_chart {
  position: absolute;
  top: -71px;
  left: -21px;
}

.ForEmployersSection_arrow {
  position: absolute;
}

.ForEmployersSection_arrow__first {
  top: -37px;
  right: -21px;
}

.ForEmployersSection_arrow__second {
  top: 26px;
  right: -29px;

  -webkit-transform: scale(1, -1) rotate(45deg);
  -moz-transform: scale(1, -1) rotate(45deg);
  -o-transform: scale(1, -1) rotate(45deg);
  transform: scale(1, -1) rotate(45deg);
}

.ForEmployersSection_description {
  display: block;

  padding-top: 8px;

  opacity: 0.5;
}
