@import url("./components/notification/notification.css");
@import url("./components/footer/footer.css");
@import url("./components/contact-us-section/contact-us-section.css");
@import url("./components/faq-section/faq-section.css");
@import url("./components//supported-crypto-section/supported-crypto-section.css");
@import url("./components/etz-mobile-section/etz-mobile-section.css");
@import url("./components/for-employers-section/for-employers-section.css");
@import url("./components/benefit-section/benefits-section.css");
@import url("./components/header-menu/header-menu.css");
@import url("./components/header-section/header-section.css");
@import url("./components/change-password-placeholder/change-password-placeholder.css");
@import url("./components/etz-founder-tax-section/etz-founder-tax-section.css");
@import url("./components/logos/logos.css");
@import url("./components/founder-logos/founder-logos.css");
@import url("./components/ira-calculator/ira-calculator.css");
@import url("./components/founder-tax-graph/founder-tax-graph.css");
