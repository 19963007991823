.HeaderMenu_menu__mobile {
  display: none;
}
.HeaderMenu_entryIconDark {
  display: none;
}

.HeaderMenu_logoLight {
  display: flex;
}

.HeaderMenu_logoDark {
  display: none;
}

.HeaderMenu_burgerBlock {
  display: none;
}

.HeaderMenu_linksBlockMobile {
  display: none;
}

.HeaderMenu_overflow {
  display: none;
}

.HeaderMenu_menu {
  position: initial;
  z-index: var(--z-index-menu);
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: var(--height-menu);

  color: var(--bright);
  background-color: transparent;
}

.HeaderMenu_menu__sticky {
  margin-top: 0;

  color: var(--text-black);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.HeaderMenu_menu__sticky .HeaderMenu_logoDark {
  display: flex;
}

.HeaderMenu_menu__sticky .HeaderMenu_logoLight {
  display: none;
}

.HeaderMenu_menu__sticky .HeaderMenu_entryIconDark {
  display: initial;
}

.HeaderMenu_menu__sticky .HeaderMenu_entryIconLight {
  display: none;
}

.HeaderMenu_menuContainer {
  z-index: var(--z-index-menu);

  display: flex;
  align-items: center;

  width: 1200px;
}

.HeaderMenu_menu__sticky.HeaderMenu_menu {
  position: fixed;
  top: 0;

  animation: show-menu 100ms linear;

  color: var(--text-black);
  background: var(--bright);
}

.HeaderMenu_menu__stickyHide.HeaderMenu_menu {
  background: transparent;
}

.HeaderMenu_initial {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: end;
  padding-left: 50px;
}

.HeaderMenu_headerGetStarted {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 26px;

  border-color: transparent;
}

.HeaderMenu_links {
  position: relative;

  margin-left: 250px;
}

#HeaderMenu_links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.HeaderMenu_links .HeaderMenu_link {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;

  position: relative;

  display: inline-block;

  margin-right: 40px;

  cursor: pointer;
  white-space: nowrap;
  letter-spacing: 0.25px;
}

.HeaderMenu_menu__sticky
  .HeaderMenu_links
  .HeaderMenu_link
  .HeaderMenu_linkText__black {
  display: inline-block;

  transition: all 300ms linear;

  color: var(--text-black);
}
.HeaderMenu_links .HeaderMenu_link .HeaderMenu_linkText__black {
  display: inline-block;

  -webkit-transform: rotateX(0deg) translateY(0px);
  -ms-transform: rotateX(0deg) translateY(0px);
  transform: rotateX(0deg) translateY(0px);

  opacity: 1;
  color: var(--bright);
}
.HeaderMenu_links .HeaderMenu_link .HeaderMenu_linkText__primary {
  position: absolute;

  display: inline-block;

  transition: all 300ms linear;

  opacity: 0;
}

.HeaderMenu_link.HeaderMenu_link__active .HeaderMenu_linkText__black {
  position: absolute;

  -webkit-transform: rotateX(90deg) translateY(-10px);
  -ms-transform: rotateX(90deg) translateY(-10px);
  transform: rotateX(90deg) translateY(-10px);

  opacity: 0;
}

.HeaderMenu_link.HeaderMenu_link__deactive .HeaderMenu_linkText__black {
  position: relative;

  -webkit-transform: rotateX(0deg) translateY(0px);
  -ms-transform: rotateX(0deg) translateY(0px);
  transform: rotateX(0deg) translateY(0px);

  opacity: 1;
}

.HeaderMenu_link.HeaderMenu_link__active .HeaderMenu_linkText__primary {
  position: relative;

  opacity: 1;
  color: var(--primary);
}

.HeaderMenu_link.HeaderMenu_link__deactive .HeaderMenu_linkText__primary {
  position: absolute;
  left: 0;

  display: inline-block;

  -webkit-transform: rotateX(-90deg) translateY(10px);
  -ms-transform: rotateX(-90deg) translateY(10px);
  transform: rotateX(-90deg) translateY(10px);

  opacity: 0;
  color: var(--primary);
}

.HeaderMenu_links .HeaderMenu_link:first-child {
  margin-left: 0;
}

.HeaderMenu_links .HeaderMenu_link:last-child {
  margin-right: 0;
}

.HeaderMenu_link__highlight {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;

  display: inline;

  margin-left: -1px;
  padding: 2px 16px;

  cursor: pointer;
  white-space: nowrap;
  letter-spacing: 0.25px;

  color: var(--highlight);
  border-radius: 16px;
  background-color: var(--dark-10);
}

.HeaderMenu_menu__sticky .HeaderMenu_link__highlight {
  color: var(--positive);
  background-color: var(--highlight-20);
}

.HeaderMenu_menu__sticky .HeaderMenu_buttonLink {
  color: var(--primary);
}

.HeaderMenu_buttonLink {
  font-size: 18px;
  font-weight: 400;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  padding: 0.8em;

  cursor: pointer;
  white-space: nowrap;

  border-radius: 0.8em;
}

.HeaderMenu_buttonIcon {
  margin-right: 12px;
}

.HeaderMenu_dashContainer {
  position: absolute;
  right: 0;
  bottom: -38px;
  left: 0;

  overflow: hidden;

  box-sizing: border-box;
  min-height: 4px;
}

.HeaderMenu_dash {
  width: 70px;
  min-height: 4px;

  transition-delay: 0ms, 0ms;
  transition-timing-function: linear;
  transition-duration: 150ms, 300ms;
  transition-property: width, transform;
  transform: translateX(-80px);

  background-color: var(--bright);
}

.HeaderMenu_menu__sticky .HeaderMenu_dash {
  background-color: var(--primary);
}

.HeaderMenu_headerGetFreeDemo {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  margin-right: 16px;
  padding: 12px 26px;
}

/* src/styles/components/header-menu/header-menu.css */
.HeaderMenu_links {
  display: flex;
  align-items: center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: transparent;
  color: var(--bright);
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 18px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #2479fe;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  min-width: 160px;
}

.dropdown-content a {
  color: rgb(255, 255, 255);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ffffff;
  color: var(--primary);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.HeaderMenu_menu__sticky .dropbtn {
  color: var(--text-black);
}
