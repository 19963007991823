.founder-tax-chart {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.chart-container {
  margin-bottom: 0px; /* Add gap between chart and description */
  width: 100%;
  height: 275px;
}

.disclaimer {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  text-align: center;
}

.EtzMobileSection_block {
  gap: 100px !important;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .chart-container {
    height: 200px;
  }

  .EtzMobileSection_block {
    justify-content: flex-start !important;
    gap: 40px !important; /* Reduced gap for mobile */
  }

  .EtzMobileSection {
    justify-content: center !important;
  }
}
